import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Card,
    Col,
    Row
} from "reactstrap";
import React, { useState } from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';
import Marquee from "react-fast-marquee";

export default function AllPartners({
    global,
    pathPrefix,
    heading,
    customClass = "",
    post,
}) {

    return (
        post?.length >= 1 && (
            <section className={`${styles.root} ${customClass}`} >
                <Container>
                    {heading ?
                        <h3>
                            <SourceFlowText global={global} path={`${pathPrefix}.heading`}>
                                {heading}
                            </SourceFlowText>
                        </h3> : ""}
                    <Marquee className="align-items-center mt-5 marquee-wrapper" speed={48} gradient={false}>
                        {post.map((post, index) => (
                            <Col
                                xs={6}
                                md={3}
                                className="d-inline-block px-2 px-sm-3 px-md-4"
                                key={index}
                            >
                                <Card>
                                    <Link href={`${post.link}`}>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={`${post?.url_slug}`}
                                        >
                                            <SourceFlowImage
                                                src={post?.image}
                                                alt={post?.title}
                                                size="1000x"
                                                className="marquee-image rounded-0 m-auto"
                                                width={240}
                                            />
                                        </a>
                                    </Link>
                                </Card>
                            </Col>
                        ))}
                    </Marquee>

                </Container>
            </section>
        )
    )
}